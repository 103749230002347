function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
define('modules/strategy/supply/pmpe/views/panel',['require','jQuery','Underscore','moment','T1','T1View','T1Layout','T1Permissions','T1Menu','collections/supplySources','collections/mediaDeals','collections/mediaDealGroups','modules/strategy/supply/pmpe/views/dealModal','text!../../shared/templates/panel.html','text!../templates/panel_layout.html','text!../../shared/templates/grid_footer.html','text!templates/grid_header.html','text!../templates/browse_item.html','text!../templates/dealGroupGrid.html','text!../templates/dealGroupDealGrid.html','text!templates/form_footer.html','text!templates/alert.html','utils/currencyConversionHelper','T1UtilsAsync'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const moment = require("moment");
  const T1 = require("T1");
  const T1View = require("T1View");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const makeMenu = require("T1Menu");
  const SupplySources = require("collections/supplySources");
  const Deals = require("collections/mediaDeals");
  const DealGroups = require("collections/mediaDealGroups");
  const dealModalView = require("modules/strategy/supply/pmpe/views/dealModal");
  const tmpl = require("text!../../shared/templates/panel.html");
  const layout_tmpl = require("text!../templates/panel_layout.html");
  const grid_footer = require("text!../../shared/templates/grid_footer.html");
  const grid_header = require("text!templates/grid_header.html");
  const browse_item = require("text!../templates/browse_item.html");
  const dealGroupGrid = require("text!../templates/dealGroupGrid.html");
  const dealGroupDealGrid = require("text!../templates/dealGroupDealGrid.html");
  const form_footer = require("text!templates/form_footer.html");
  const alertDialog = require("text!templates/alert.html");
  const CurrencyUtils = require("utils/currencyConversionHelper");
  const T1UtilsAsync = require("T1UtilsAsync");
  var PmpePanel = T1View.extend({
    template: tmpl,
    noCheckUnsavedState: true,
    contentType: "deals",
    currentGrid: "deals",
    partials: {
      form_footer: form_footer,
      alertDialog: alertDialog,
      dealGroupDealGrid: dealGroupDealGrid
    },
    canRunOnAll: true,
    dataEvents: {
      model: {
        "change:run_on_all_pmp": "setRunOnAllCheckbox"
      }
    },
    events: {
      "click .hideIcon": "hideWarning",
      "change .checkBoxHolder label": "toggleRunOnAll",
      "click .add-button-c": "onAddDealClick",
      "click .open-unpermissioned-deals-modal": "openUnpermissionedDealsModal",
      "click .close-unpermissioned-deals-modal": "closeUnpermissionedDealsModal",
      "click .media-type-switcher": "toggleMediaGrid"
    },
    eventhubEvents: {
      "supply:saveSuccessful": "onSaveSuccessful",
      "supply:deal:regrid": "regrid",
      "pmpe:noResults": "disableRunOnAll",
      "supply:cancelTypeChange": "setRunOnAllCheckbox",
      "hasUnpermissionedDeals:updateBins": "showUnpermissionedDealsWarning",
      "hideUnpermissionedDealsWarning:reloadBins": "hideUnpermissionedDealsWarning",
      "pmpe:getCurrentGrid": "getCurrentGrid",
      "pmpe:collFetched": function ({
        coll: coll
      }) {
        if (coll.length && !this.isPGStrategy) {
          this.$(".checkBoxHolder .checkbox").prop("disabled", false);
        }
        this.collection = coll;
        if (this.targetAllFlagEnabled && parseInt(this.model.get("run_on_all_pmp"), 10)) {
          this.sharedCollection.add(this.collection.models, {
            silent: true
          });
        }
        this.layout.collection = coll;
      },
      "pmpe:dealGroupCollectionFetched": "handleDealGroupCollectionFetched"
    },
    initialize: function ({
      sharedCollection: sharedCollection,
      sharedFptCollection: sharedFptCollection,
      isPGStrategy: isPGStrategy,
      addOrRemoveSharedCollection: addOrRemoveSharedCollection,
      targetAllFlagEnabled: targetAllFlagEnabled,
      mapItemsBins: mapItemsBins
    }) {
      this.sharedCollection = sharedCollection;
      this.sharedFptCollection = sharedFptCollection;
      this.isPGStrategy = isPGStrategy;
      this.addOrRemoveSharedCollection = addOrRemoveSharedCollection;
      this.disableDealAddEdit = T1Permissions.check("feature", "markets_pmpe_add_edit");
      this.hasUnpermissionedDeals = this.sharedCollection.hasUnpermissionedDeals;
      this.targetAllFlagEnabled = targetAllFlagEnabled;
      this.mapItemsBins = mapItemsBins;
      const foldTargetValues = this.sharedFptCollection.models.slice();
      this.model.set({
        cached_fold_target_values: foldTargetValues
      });
      this.sanitizeCollection(this.sharedCollection);
      this.updateStrategyModel();
    },
    updateStrategyModel: function () {
      this.model.tempSupplyInfo = {
        run_on_all_exchanges: this.model.get("run_on_all_exchanges"),
        run_on_all_pmp: this.model.get("run_on_all_pmp")
      };
    },
    showUnpermissionedDealsWarning: function () {
      this.hasUnpermissionedDeals = this.sharedCollection.hasUnpermissionedDeals;
      this.regrid();
    },
    hideUnpermissionedDealsWarning: function () {
      this.hasUnpermissionedDeals = false;
    },
    toggleMediaGrid: function () {
      const $addAllCheckbox = this.$(".add-all");
      const $dealsGrid = $(this.$(".browser-c")[0]);
      const $dealGroupsGrid = this.$(".deal-group-grid");
      const $dealsSearchFilter = this.$(".deals-search-filter");
      const $dealGroupsSearchFilter = this.$(".deal-groups-search-filter");
      const dealsRadioButton = this.$(".media-type-switcher .deals-radio-box")[0];
      const dealGroupsRadioButton = this.$(".media-type-switcher .deal-groups-radio-box")[0];
      this.$(".grid-loader").hide();
      if (dealsRadioButton.checked) {
        $dealGroupsGrid.hide();
        $dealGroupsSearchFilter.hide();
        $addAllCheckbox.removeClass("hideAddAllCheckbox");
        $dealsGrid.show();
        $dealsSearchFilter.show();
        this.currentGrid = "deals";
        T1.EventHub.publish("pmpeSupplyTab:currentGrid", "deals");
      } else if (dealGroupsRadioButton) {
        $dealsGrid.hide();
        $dealsSearchFilter.hide();
        $addAllCheckbox.addClass("hideAddAllCheckbox");
        $dealGroupsGrid.show();
        $dealGroupsSearchFilter.show();
        this.currentGrid = "deal_groups";
        T1.EventHub.publish("pmpeSupplyTab:currentGrid", "deal_groups");
      }
    },
    load: function () {
      const {
        model: model,
        sharedCollection: sharedCollection
      } = this;
      const containsPMPDeals = sharedCollection.containsPMPDeals();
      const runOnAllPMP = model.get("run_on_all_pmp");
      if (!this.targetAllFlagEnabled) {
        this.sharedFptCollection.reset(model.get("cached_fold_target_values"));
      }
      if (sharedCollection.hasUnpermissionedDeals && containsPMPDeals && sharedCollection.length > sharedCollection.permissionedDealCount) {
        const unpermissionedFetch = T1UtilsAsync.makeDeferral();
        const campaign = model.get("campaign");
        const advertiserId = campaign.get("advertiser_id");
        const currencyCode = campaign.get("currency_code");
        sharedCollection.fetchPermissionedDeals(advertiserId, currencyCode, unpermissionedFetch, true);
        unpermissionedFetch.promise.then(() => {
          this.sanitizeCollection(sharedCollection);
          this.initializeLayout();
          this.renderPanel();
        });
      } else if (!containsPMPDeals && !parseInt(runOnAllPMP, 10)) {
        if (!model.get("cached_run_on_all_pmp")) {
          model.set({
            cached_run_on_all_pmp: runOnAllPMP
          });
        }
        this.hasUnpermissionedDeals = false;
        this.initializeLayout();
        this.renderPanel();
      } else {
        if (!model.get("cached_run_on_all_pmp")) {
          model.set({
            cached_run_on_all_pmp: runOnAllPMP
          });
        }
        this.hasUnpermissionedDeals = false;
        this.initializeLayout();
        this.renderPanel();
      }
    },
    generateDealObj: function () {
      const models = this.sharedCollection.hasUnpermissionedDeals ? this.sharedCollection.getPermissionedDeals() : this.sharedCollection.models;
      if (!this.sharedCollection.dealObj) {
        this.sharedCollection.dealObj = {};
      }
      models.forEach(model => {
        if (model.get("type") === "deal") {
          this.sharedCollection.dealObj[model.get("id")] = model;
        }
      });
    },
    renderPanel: function () {
      this.render().then(() => {
        if (this.layout) {
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "supply_targeting"
          });
          this.layout.load().then(() => {
            const viewObj = this.layout.options ? this.layout.options.layout[".browser-c"][0].view : undefined;
            if (viewObj) {
              viewObj.searchTerm = undefined;
            }
            if (this.isPGStrategy) {
              $(".tab-1-level-2").addClass("li-disabled");
              $(".tab-3-level-2").addClass("li-disabled");
              this.$(".deal-groups-radio-label").addClass("li-disabled");
              this.$(".exclusivenessCheckBoxLabel").addClass("li-disabled");
            }
            if (this.collection) {
              this.collection.searchDeals(undefined, "", "1");
            }
            this.el.trigger("subViewLoad");
          });
        }
        this.setRunOnAllCheckbox();
        if (!this.targetAllFlagEnabled) {
          this.checkWarning(this.sharedCollection.length, this.sharedCollection.contentType);
        }
      });
    },
    regrid: function () {
      this.load();
    },
    checkWarning: function (len, type) {
      var viewType = this.contentType;
      var action = len > 0 && viewType !== type ? "show" : "hide";
      T1.EventHub.publish(`supply:${action}Warning`, viewType);
    },
    hideWarning: function (evt) {
      this.preventDefault(evt);
      this.checkWarning(0, "hide-warning");
    },
    initializeLayout: function () {
      let unpermissionedDealCount;
      var filterClass = "filter-pmpe-selectbox";
      var filterAndClearCallback = function (term) {
        var filter = $(this.el).find(`.${filterClass}`).val();
        term = term.replace(/%26/g, "&");
        T1.EventHub.publish("pmpe:search", {
          term: term || "",
          filter: filter
        });
      };
      if (this.sharedCollection.hasUnpermissionedDeals) {
        this.hasUnpermissionedDeals = true;
        unpermissionedDealCount = this.sharedCollection.unpermissionedDeals.length;
      }
      this.generateDealObj();
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: layout_tmpl,
        serialize: function () {
          var coll = this.collection;
          var serializeData = {
            disableCheckbox: !coll || !coll.length,
            unpermissionedDealCount: unpermissionedDealCount
          };
          return serializeData;
        },
        layout: {
          ".filter": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: filterAndClearCallback,
              clearFilterCallback: filterAndClearCallback,
              selectOptions: [{
                text: "Deal Name",
                value: "name"
              }, {
                text: "Deal ID",
                value: "deal_identifier"
              }],
              filterClass: filterClass,
              maxlength: "256"
            }
          }],
          ".deal-groups-filter": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: filterAndClearCallback,
              clearFilterCallback: filterAndClearCallback,
              selectOptions: [{
                text: "Group Name",
                value: "name"
              }],
              filterClass: filterClass,
              maxlength: "256"
            }
          }],
          ".select-status": [{
            module: "strategy/supply/pmpe",
            viewType: "activeState",
            options: {
              model: this.model
            }
          }],
          ".deal-groups-select-status": [{
            module: "strategy/supply/pmpe",
            viewType: "activeState",
            options: {
              model: this.model
            }
          }],
          ".browser-c": [{
            module: "strategy/supply/shared",
            viewType: "browse",
            showLoader: true,
            options: this.getBrowseOptions()
          }],
          ".deal-group-grid": [{
            module: "strategy/supply/shared",
            viewType: "browse",
            showLoader: true,
            options: this.getDealGroupGridOptions()
          }],
          ".bins-column": [{
            module: "strategy/supply/shared",
            viewType: "bins",
            showLoader: true,
            options: {
              sharedCollection: this.sharedCollection,
              contentType: this.contentType,
              mapItems: this.mapItemsBins,
              model: this.model,
              hasUnpermissionedDeals: this.hasUnpermissionedDeals
            }
          }]
        }
      });
    },
    getBrowseOptions: function () {
      return {
        viewInstance: "dealView",
        model: this.model,
        sharedCollection: this.sharedCollection,
        sharedFptCollection: this.sharedFptCollection,
        contentType: this.contentType,
        checkboxAttr: "run_on_all_pmp",
        isVideoStrategy: this.isVideoStrategy(this),
        disableDealAddEdit: this.disableDealAddEdit,
        isPGStrategy: this.isPGStrategy,
        partials: {
          gridHeader: grid_header,
          gridFooter: this.model.get("media_type") === "VIDEO" ? null : grid_footer,
          listItem: browse_item
        },
        sortConfigs: {
          order: "ascending",
          sortBy: function (data) {
            var order = this.sortOrder;
            this.currentSortField = data;
            this.sortOrder = order ? -order : 1;
            this.collection.sortGrid(data, this.sortOrder);
          }
        },
        dataEvents: {
          collection: {
            reset: "load",
            "change:deal": "updateShColl"
          },
          sharedCollection: {
            add: function (model) {
              if (model.get("type") === "deal") {
                this.sharedCollection.dealObj[model.id] = model;
              }
              this.addOrRemoveSharedCollection(this, model, "add");
            },
            remove: function (model) {
              if (model.get("type") === "deal") {
                delete this.sharedCollection.dealObj[model.id];
              }
              this.addOrRemoveSharedCollection(this, model, "remove");
            },
            toggleAllPMP: "renderAllItems"
          }
        },
        eventhubEvents: {
          "pmpe:search": "search",
          "pmpe:toggleAll": "toggleRunOnAllCheckbox",
          "pmpe:changeActiveState": "changeActiveState",
          "pmpe:changeSearchFilter": "changeSearchFilter",
          "supply:changeTypeByItem:deals": function (data) {
            this.closeChangeTypeBy("item")(data);
          },
          "supply:changeTypeByItems:deals": function (data) {
            this.closeChangeTypeBy("items")(data);
          },
          "supply:changeTypeByAll:deals": "closeChangeTypeByAll",
          "pmpeSupplyTab:currentGrid": this.setCurrentGrid
        },
        createCollection: function () {
          const campaign = this.model.get("campaign");
          const advertiserId = campaign.get("advertiser_id");
          const organizationId = campaign.get("advertiser").get("agency").get("organization_id");
          const currencyCode = campaign.get("currency_code");
          const promises = [];
          const collFetch = T1UtilsAsync.makeDeferral();
          const supplyFetch = T1UtilsAsync.makeDeferral();
          const useV2 = T1Permissions.check("feature", "deals_v2_api");
          const isAcctAndAddr = T1Permissions.check("feature", "source") && campaign.get("advertiser").get("accountable_and_addressable") === "1";
          promises.push(collFetch.promise);
          promises.push(supplyFetch.promise);
          this.collection = new Deals(false, {
            fetchOptions: _objectSpread(_objectSpread({
              full: "*",
              "permissions.advertiser_id": advertiserId
            }, useV2 && {
              "owner.organization_id": organizationId
            }), {}, {
              currency_code: currencyCode,
              status: true
            }),
            isPaginated: false
          });
          if (this.isPGStrategy) {
            $(".deal-groups-radio-box").attr("disabled", "");
            this.collection.fetchOptions.deal_type = "PG";
          }
          this.collection.fetchAllPages({
            success: function () {
              collFetch.resolve();
            }
          });
          this.supplyColl = new SupplySources(false, {
            fetchOptions: {
              full: "*"
            },
            isPaginated: false
          });
          this.supplyColl.fetchAllPages({
            success: function () {
              supplyFetch.resolve();
            }
          });
          T1UtilsAsync.linkPromises(promises).then(() => {
            if (this.targetAllFlagEnabled) {
              this.sharedCollection.setTypeIds(this.collection);
            } else {
              this.sharedCollection.setModelIds(this.collection);
            }
            const supplyColl = this.supplyColl;
            this.collection.models = this.collection.models.filter(function (model) {
              const supplySourceId = model.get("supply_source_id");
              const supplySource = supplyColl.get(supplySourceId);
              if (!isAcctAndAddr) {
                if (supplySource && supplySource.get("accountable_and_addressable") === "0") {
                  return supplySource && model.set({
                    supply_source_name: supplySource.get("name")
                  });
                }
              } else {
                return supplySource && model.set({
                  supply_source_name: supplySource.get("name")
                });
              }
              return false;
            });
            if (parseInt(this.model.get("run_on_all_pmp"), 10)) {
              this.collection.models.forEach(deal => {
                this.sharedCollection.dealObj[deal.id] = deal;
              });
            }
            this.sanitizeCollection(this.collection);
            this.collection = CurrencyUtils.updatePMPePriceWithCurrency(this.collection, currencyCode);
            T1.EventHub.publish("pmpe:collFetched", {
              coll: this.collection
            });
            this.load();
          });
        },
        searchCollection: function (params) {
          if (!this.currentGrid) {
            T1.EventHub.publish("pmpe:getCurrentGrid");
          }
          if (this.currentGrid === "deals") {
            this.searchTerm = params.term !== "" ? params.term : undefined;
            this.filter = params.filter;
            this.collection.searchDeals(this.searchTerm, this.filter, this.state, this.currentSortField, this.sortOrder);
          }
        },
        serialize: this.serializeBrowse,
        styleForSearch: this.styleForBrowse,
        styleForBrowse: this.styleForBrowse,
        sanitizeCollection: this.sanitizeCollection,
        showLoader: this.showLoader,
        getHeaders: this.getHeaders,
        changeActiveState: function (state) {
          this.state = state;
          this.showLoader();
          if (this.state === "both") {
            delete this.collection.fetchOptions.status;
          } else {
            this.collection.fetchOptions.status = this.state === "1";
          }
          const reloadBrowse = this.load.bind(this);
          this.collection.reset([], {
            silent: true
          });
          this.collection.fetchAllPages({
            success: function () {
              reloadBrowse();
            }
          });
        },
        showSettings: this.showSettings,
        editDeal: this.editDeal,
        updateShColl: this.updateShColl,
        addOrRemoveSharedCollection: this.addOrRemoveSharedCollection
      };
    },
    getDealGroupGridOptions: function () {
      return {
        viewInstance: "dealGroupView",
        model: this.model,
        sharedCollection: this.sharedCollection,
        sharedFptCollection: this.sharedFptCollection,
        contentType: "deal_groups",
        checkboxAttr: "run_on_all_pmp",
        isVideoStrategy: this.isVideoStrategy(this),
        disableDealAddEdit: this.disableDealAddEdit,
        partials: {
          gridHeader: grid_header,
          gridFooter: this.model.get("media_type") === "VIDEO" ? null : grid_footer,
          listItem: dealGroupGrid
        },
        sortConfigs: {
          order: "ascending",
          sortBy: function (data) {
            var order = this.sortOrder;
            this.currentSortField = data;
            this.sortOrder = order ? -order : 1;
            this.collection.sortGrid(data, this.sortOrder);
          }
        },
        dataEvents: {
          collection: {
            reset: "load",
            "change:deal": "updateShColl"
          },
          sharedCollection: {
            add: function (model) {
              this.addOrRemoveSharedCollection(this, model, "add");
            },
            remove: function (model) {
              this.addOrRemoveSharedCollection(this, model, "remove");
            },
            toggleAllPMP: this.renderDealGroups
          }
        },
        eventhubEvents: {
          "pmpe:search": "search",
          "pmpe:changeDealGroupActiveState": "changeDealGroupActiveState",
          "pmpe:changeSearchFilter": "changeSearchFilter",
          "supply:changeTypeByItem:deal_groups": function (data) {
            this.closeChangeTypeBy("item")(data);
          },
          "supply:changeTypeByItems:deal_groups": function (data) {
            this.closeChangeTypeBy("items")(data);
          },
          "pmpeSupplyTab:currentGrid": this.setCurrentGrid
        },
        createCollection: function () {
          const strategyId = this.model.get("id");
          const campaign = this.model.get("campaign");
          const organizationId = campaign.get("advertiser").get("agency").get("organization_id");
          const currencyCode = campaign.get("currency_code");
          const promises = [];
          const collFetch = T1UtilsAsync.makeDeferral();
          const supplyFetch = T1UtilsAsync.makeDeferral();
          const useV2 = T1Permissions.check("feature", "deals_v2_api");
          const isAcctAndAddr = T1Permissions.check("feature", "source") && campaign.get("advertiser").get("accountable_and_addressable") === "1";
          promises.push(collFetch.promise);
          promises.push(supplyFetch.promise);
          this.collection = new DealGroups(false, {
            fetchOptions: _objectSpread(_objectSpread({
              full: "*",
              strategy_id: strategyId,
              currency_code: currencyCode
            }, useV2 && {
              "owner.organization_id": organizationId
            }), {}, {
              status: true
            }),
            isPaginated: false
          });
          this.collection.fetchAllPages({
            success: function () {
              collFetch.resolve();
            }
          });
          this.supplyColl = new SupplySources(false, {
            fetchOptions: {
              full: "*"
            },
            isPaginated: false
          });
          if (!isAcctAndAddr) {
            this.supplyColl.search.set({
              params: [{
                searchField: "accountable_and_addressable",
                term: "0"
              }]
            });
          }
          this.supplyColl.fetchAllPages({
            success: function () {
              supplyFetch.resolve();
            }
          });
          T1UtilsAsync.linkPromises(promises).then(() => {
            if (this.targetAllFlagEnabled) {
              this.sharedCollection.setTypeIds(this.collection);
            } else {
              this.sharedCollection.setModelIds(this.collection);
            }
            this.sanitizeCollection(this.collection);
            this.collection = CurrencyUtils.updatePMPePriceWithCurrency(this.collection, currencyCode);
            T1.EventHub.publish("pmpe:dealGroupCollectionFetched", {
              collection: this.collection
            });
            this.load();
          });
        },
        searchCollection: function ({
          term: term,
          filter: filter
        }) {
          if (!this.currentGrid) {
            T1.EventHub.publish("pmpe:getCurrentGrid");
          }
          if (this.currentGrid === "deal_groups") {
            this.searchTerm = term !== "" ? term : undefined;
            this.filter = filter;
            this.collection.searchDealGroups(this.searchTerm, this.filter, this.state, this.currentSortField, this.sortOrder);
          }
        },
        serialize: this.serializeBrowse,
        styleForSearch: this.styleForDealGroup,
        styleForBrowse: this.styleForDealGroup,
        sanitizeCollection: this.sanitizeCollection,
        getHeaders: this.getDealGroupGridHeaders,
        changeDealGroupActiveState: function (state) {
          this.state = state;
          if (this.state === "both") {
            delete this.collection.fetchOptions.status;
          } else {
            this.collection.fetchOptions.status = this.state === "1";
          }
          const reloadBrowse = this.load.bind(this);
          this.collection.reset([], {
            silent: true
          });
          this.collection.fetchAllPages({
            success: function () {
              reloadBrowse();
            }
          });
        },
        showSettings: this.showSettings,
        updateShColl: this.updateShColl,
        addOrRemoveSharedCollection: this.addOrRemoveSharedCollection,
        handleToggleGridExpanison: function (event) {
          const self = this;
          let customNoResultMessage;
          const $itemWrapper = $(event.currentTarget).closest(".item-wrapper");
          const $item = $itemWrapper.find(".item");
          const $iconArrow = $itemWrapper.find(".icon-arrow");
          const $expansion = $item.find(".expansion");
          const dealGroupId = $item.attr("data-id");
          const dealGroup = this.collection.get(dealGroupId);
          const campaign = this.model.get("campaign");
          const advertiserId = campaign.get("advertiser_id");
          const organizationId = campaign.get("advertiser").get("agency").get("organization_id");
          const currencyCode = campaign.get("currency_code");
          const dealsQuery = `(${dealGroup.get("deal_ids").join(",")})`;
          const supplyColl = this.supplyColl;
          const useV2 = T1Permissions.check("feature", "deals_v2_api");
          const isAcctAndAddr = T1Permissions.check("feature", "source") && campaign.get("advertiser").get("accountable_and_addressable") === "1";
          const dealsCollection = new Deals(false, {
            fetchOptions: _objectSpread(_objectSpread({
              full: "*",
              q: dealsQuery,
              "permissions.advertiser_id": advertiserId
            }, useV2 && {
              "owner.organization_id": organizationId
            }), {}, {
              currency_code: currencyCode
            })
          });
          if ($iconArrow.hasClass("icon-arrow-down")) {
            $iconArrow.removeClass("icon-arrow-down");
          } else {
            $iconArrow.addClass("icon-arrow-down");
          }
          if ($expansion.css("display") === "none") {
            dealsCollection.fetchAllPages({
              success: function (response) {
                const dealsData = response;
                if (!isAcctAndAddr) {
                  dealsData.models = dealsData.models.filter(model => {
                    const supplySourceId = model.get("supply_source_id");
                    const supplySource = supplyColl.get(supplySourceId);
                    if (supplySource && supplySource.get("id") === supplySourceId) {
                      if (supplySource.get("accountable_and_addressable") === "0") {
                        return true;
                      }
                    }
                    return false;
                  });
                  if (!dealsData.models.length) {
                    customNoResultMessage = "No deals available";
                  }
                }
                self.sanitizeCollection(dealsData);
                dealGroup.set({
                  deals: dealsData.models
                });
                self.addGridSubItem($expansion[0], dealGroup, customNoResultMessage);
                $expansion.removeClass("no-display");
              }
            });
          } else {
            $expansion.addClass("no-display");
          }
        },
        addGridSubItem: function (element, dealGroup, customNoResultMessage) {
          const list = dealGroup.get("deals").filter(deal => this.supplyColl.get(deal.get("supply_source_id"))).map(deal => ({
            name: deal.get("name"),
            deal_id: deal.get("deal_identifier"),
            deal_type: deal.get("deal_type"),
            supply_source: this.supplyColl.get(deal.get("supply_source_id")).get("name"),
            start_date: deal.get("start"),
            end_date: deal.get("end"),
            cpm: deal.get("price"),
            status_class: deal.get("status") === "1" ? "active" : "inactive"
          }));
          this.renderPartialTemplate({
            template: dealGroupDealGrid,
            useTemplateHtml: false,
            action: "html",
            targetEl: element,
            data: {
              list: list,
              customNoResultMessage: customNoResultMessage
            }
          });
        }
      };
    },
    getCurrentGrid: function () {
      T1.EventHub.publish("pmpeSupplyTab:currentGrid", this.currentGrid);
    },
    renderDealGroups: function () {
      const $items = this.$(".item").not(".can-toggle");
      $items.removeClass("selected").find(".icon").removeClass("active");
      this.finishRendering();
    },
    setCurrentGrid: function (grid) {
      this.currentGrid = grid;
    },
    handleDealGroupCollectionFetched: function ({
      collection: collection
    }) {
      if (collection.length && !this.isPGStrategy) {
        this.$(".checkBoxHolder .checkbox").prop("disabled", false);
      }
      this.dealGroupCollection = collection;
      this.layout.dealGroupCollection = collection;
    },
    updateShColl: function (model) {
      const campaign = this.model.get("campaign");
      const advertiserId = campaign.get("advertiser_id");
      const currencyCode = campaign.get("currency_code");
      const tempColl = new DealGroups(false, {
        fetchOptions: {
          full: "*",
          "permissions.advertiser_id": advertiserId,
          currency_code: currencyCode
        },
        isPaginated: false
      });
      let shColl = this.sharedCollection;
      const {
        collection: collection,
        supplyColl: supplyColl
      } = this;
      const inShared = shColl.get(model.id);
      tempColl.fetchAllPages({
        success: function () {
          _.map(tempColl.models, function (tempModel) {
            return tempModel.set({
              supply_source_name: supplyColl.get(tempModel.get("supply_source_id")).get("name")
            });
          });
          if (inShared) {
            model.set({
              action: "include"
            });
            shColl.remove(inShared);
            shColl.add(model);
            shColl = CurrencyUtils.updatePMPePriceWithCurrency(shColl, currencyCode);
          }
          collection.reset([], {
            silent: true
          });
          collection.reset(tempColl.models);
        }
      });
    },
    editDeal: function (event) {
      const id = this.settingsDealId || null;
      if (id) {
        const deal = this.collection.get(id);
        $(document).trigger("click");
        event.preventDefault(event);
        event.stopPropagation();
        dealModalView.showDialog({
          mode: "edit",
          model: deal,
          collection: this.collection,
          strategy: this.model
        });
      }
    },
    showSettings: function (event) {
      var $currentTarget = $(event.currentTarget);
      var $menuTarget = $currentTarget.find("a");
      this.preventDefault(event);
      const menuConfig = {
        menuTriggerEleSelector: ".menuTarget",
        menuColumns: [{
          columnTitle: null,
          hideIcons: false,
          menuItems: [{
            label: "Edit",
            iconClass: "edit-strategy",
            handler: "editDeal",
            visible: true
          }]
        }]
      };
      this.settingsDealId = $menuTarget.data("id");
      makeMenu(menuConfig, this);
      $currentTarget.find(".menuTarget").trigger("click");
    },
    isVideoStrategy: function (context) {
      var model = context ? context.model : this.model;
      return model.get("media_type") === "VIDEO";
    },
    serializeBrowse: function (models) {
      var tdata = {};
      const {
        collection: collection,
        sharedFptCollection: folds,
        targetAllFlagEnabled: targetAllFlagEnabled
      } = this;
      var contentType = this.sharedCollection.contentType;
      if (!models) {
        models = collection ? collection.models : [];
      }
      if (folds) {
        tdata.aboveFPT = folds.get("45054");
        tdata.belowFPT = folds.get("45055");
        tdata.uncategorizedFPT = folds.get("45056");
      }
      tdata.ascending = this.sortOrder === 1;
      tdata.canWriteFpt = targetAllFlagEnabled || contentType !== "site_placement";
      tdata.headers = this.getHeaders(this.currentSortField);
      tdata.list = this.chooseRenderStyle(this.searchTerm, models);
      tdata.contentType = "browse-list";
      return tdata;
    },
    styleForBrowse: function (models, term) {
      const highlight = T1.Utils.highlightText;
      const shColl = this.sharedCollection;
      const isAll = this.model.get(this.checkboxAttr) === "1";
      const canWrite = this.targetAllFlagEnabled || this.canWriteSharedCollection();
      function parseDate(date) {
        return date ? moment(date).format("MM/DD/YYYY") : undefined;
      }
      function getEndDate(end) {
        const comparison = T1.compareDates(Date.parse(end), Date.parse("2999-12-31"));
        return comparison >= 0 ? "Never" : parseDate(end);
      }
      return models.filter(model => model.get("type") === "deal").map(model => {
        let selected;
        const json = model.toJSON();
        if (this.targetAllFlagEnabled) {
          selected = shColl.get(json.id) && shColl.get(json.id).get("type") === "deal";
        } else {
          selected = canWrite && shColl.get(json.id);
        }
        json.createdDate = parseDate(json.created_on);
        json.startDate = parseDate(json.start_datetime);
        json.modDate = parseDate(json.updated_on);
        json.endDate = getEndDate(json.end_datetime);
        json.tipStatus = json.status === "1" ? "Active" : "Inactive";
        json.identifier = json.deal_identifier;
        json.status_class = json.status === "0" ? "inactive" : "active";
        json.supplySourceName = json.supply_source_name || "";
        if (term) {
          json.name = highlight(json.name, term);
          json.identifier = highlight(json.identifier || "", term);
        }
        if (selected || isAll) {
          json.isActive = true;
        }
        return json;
      });
    },
    styleForDealGroup: function (models, term) {
      const type = "deal_group";
      const highlight = T1.Utils.highlightText;
      const shColl = this.sharedCollection;
      const isAll = this.model.get(this.checkboxAttr) === "1";
      const canWrite = this.targetAllFlagEnabled || this.canWriteSharedCollection();
      function parseDate(date) {
        return date ? moment(date).format("MM/DD/YYYY") : undefined;
      }
      return models.filter(model => model.get("entity_type") === type).map(model => {
        let selected;
        const json = model.toJSON();
        if (this.targetAllFlagEnabled) {
          selected = shColl.get(json.id) && shColl.get(json.id).get("entity_type") === type;
        } else {
          selected = canWrite && shColl.get(json.id);
        }
        json.createdDate = parseDate(json.created_on);
        json.modDate = parseDate(json.updated_on);
        json.type = type;
        if (term) {
          json.name = highlight(`${json.name}`, term);
          json.id = highlight(`${json.id}` || "", term);
        }
        if (selected || isAll) {
          json.isActive = true;
        }
        return json;
      });
    },
    disableRunOnAll: function (isDisabled) {
      const $chkbxHolder = this.$(".checkBoxHolder");
      var cssClass = isDisabled ? "disable" : "enable";
      this.canRunOnAll = !isDisabled;
      $chkbxHolder.addClass(`${cssClass}-grid`);
    },
    toggleRunOnAll: function (event) {
      event.preventDefault();
      if (this.canRunOnAll) {
        T1.EventHub.publish("pmpe:toggleAll");
      }
    },
    toggleDealGroupRadioButton: function (activateDealGroupRadioButton) {
      const $dealGroupRadioButton = this.el.find(".deal-groups-radio-box");
      const $dealGroupRadioButtonLabel = this.el.find(".deal-groups-radio-box + label");
      if (activateDealGroupRadioButton) {
        $dealGroupRadioButton.removeAttr("disabled");
        $dealGroupRadioButtonLabel.removeAttr("disabled");
      } else {
        $dealGroupRadioButton.attr("disabled", "disabled");
        $dealGroupRadioButtonLabel.attr("disabled", "disabled");
      }
    },
    onAddDealClick: function (event) {
      var campaign = this.model.get("campaign");
      event.preventDefault();
      dealModalView.showDialog({
        mode: "create",
        strategy: this.model,
        advertiser_id: campaign.get("advertiser_id"),
        collection: this.collection
      });
    },
    openUnpermissionedDealsModal: function (event) {
      event.preventDefault();
      const modal = this.$(".unpermissioned-deals-modal");
      const grid = modal.find(".unpermissioned-deals-grid")[0];
      grid.data = this.sharedCollection.generateUnpermissionedGridData();
      modal[0].show();
    },
    closeUnpermissionedDealsModal: function (event) {
      if (event) {
        event.preventDefault();
      }
      this.$(".unpermissioned-deals-modal")[0].hide();
    },
    getHeaders: function (sortField) {
      const headers = [{
        label: "Name",
        name: "deal-name",
        isSortable: true,
        field: "name"
      }, {
        label: "Deal Id",
        name: "deal-id",
        isSortable: true,
        field: "deal_identifier"
      }, {
        label: "Deal Type",
        name: "deal-type",
        isSortable: true,
        field: "deal_type"
      }, {
        label: "Supply Source",
        name: "deal-supply-source",
        isSortable: true,
        field: "supply_source_name"
      }, {
        label: "Start",
        name: "deal-start",
        isSortable: true,
        field: "start"
      }, {
        label: "End",
        name: "deal-end",
        isSortable: true,
        field: "end"
      }, {
        label: "cpm",
        name: "deal-price",
        isSortable: true,
        field: "price"
      }];
      return _.map(headers, function findSortedColumn(header) {
        var field = header.field;
        header.showSortOrder = field && field === sortField;
        return header;
      });
    },
    getDealGroupGridHeaders: function (sortField) {
      const headers = [{
        label: "Group Name",
        name: "deal-group-name",
        isSortable: true,
        field: "name"
      }, {
        label: "Group Id",
        name: "deal-group-id",
        isSortable: true,
        field: "id"
      }];
      return _.map(headers, function findSortedColumn(header) {
        var field = header.field;
        header.showSortOrder = field && field === sortField;
        return header;
      });
    },
    sanitizeCollection: function (coll) {
      coll.models = coll.models.map(model => {
        model.set({
          name: T1.Utils.escapeHTML(model.get("name"))
        });
        if (model.get("type") === "deal") {
          model.set({
            deal_identifier: T1.Utils.escapeHTML(model.get("deal_identifier"))
          });
        }
        return model;
      });
    },
    showLoader: function () {
      if (!this.gridLoader) {
        this.gridLoader = T1.Loader.create({
          target: ".w-DataGrid .w-body.content",
          text: "Loading ...",
          contentClass: "search-loader loader-overrides grid-loader",
          spinnerConfig: {
            lines: 11,
            length: 1,
            radius: 3,
            left: 1,
            top: 0
          }
        });
      }
      this.$(".no-results").remove();
      this.gridLoader.start();
    },
    setRunOnAllCheckbox: function () {
      var $checkbox = $(".checkBoxHolder .checkbox", this.el);
      var isAllPmp = this.model.get("run_on_all_pmp") === "1";
      if (!this.isPGStrategy) {
        this.toggleDealGroupRadioButton(!isAllPmp);
      }
      $checkbox.prop("checked", isAllPmp);
    },
    onSaveSuccessful: function () {
      this.stopSpinner();
      if (!this.sharedCollection.changedWhileSaving) {
        this.sharedCollection.isFormElementsChanged = false;
      }
      this.updateFooterMessage({
        success: true
      });
      this.processSaveAndContinueMode();
      T1.EventHub.publish("strategy-create-edit:reloadSubViews");
      this.sharedCollection.cachedSupplySources = this.sharedCollection.models.slice();
      this.model.set({
        cached_run_on_all_pmp: this.model.get("run_on_all_pmp")
      });
      this.updateStrategyModel();
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    canUnload: function (fromT1FormCancelButton) {
      return !(fromT1FormCancelButton === true);
    },
    postData: function () {
      var isAllPmp = this.model.get("run_on_all_pmp") === "1";
      var isAllEx = this.model.get("run_on_all_exchanges") === "1";
      var hasSelections = Boolean(this.sharedCollection.length);
      T1.EventHub.publish(isAllPmp || isAllEx || hasSelections ? "supply:save" : "supply:noSelection");
    },
    chooseRenderStyle: function () {}
  });
  return T1.Form.ViewUtils.extend(PmpePanel);
});
