function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
define('modules/organization/utils/Utils',['require','models/organizationSetting','T1','T1UtilsAsync'],function (require) {
  "use strict";

  const OrgSettings = require("models/organizationSetting");
  const T1 = require("T1");
  const T1UtilsAsync = require("T1UtilsAsync");
  function argsRequired() {
    throw Error("Org ID is required to fetch Org settings");
  }
  const orgLookup = {};
  const valueToBool = T1.Utils.valueToBool;
  const defaults = Object.freeze({
    default_us_settings: true,
    impressions_by_vcr: false
  });
  return {
    getOrgVcrSetting() {
      const data = orgLookup[this.selectedOrg] || {};
      return Reflect.has(data, "impressions_by_vcr") ? valueToBool(data.impressions_by_vcr) : defaults.impressions_by_vcr;
    },
    getOrgSettings(orgID = this.selectedOrg) {
      let settings;
      if (orgLookup[orgID]) {
        const data = orgLookup[orgID];
        settings = Object.entries(defaults).reduce((obj, [key, value]) => {
          obj[key] = data[key] ? valueToBool(data[key]) : value;
          return obj;
        }, {});
      }
      return settings || _objectSpread({}, defaults);
    },
    fetchOrgSettings(orgID = argsRequired, isSelectedOrg) {
      if (isSelectedOrg) {
        this.selectedOrg = orgID;
      }
      const deferred = T1UtilsAsync.makeDeferral();
      this.settingsModel = new OrgSettings(null, {});
      this.settingsModel.id = orgID;
      this.settingsModel.fetch({
        success(model) {
          orgLookup[orgID] = model.toJSON();
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    saveOrgSettings(...args) {
      this.settingsModel.saveSettings(...args);
    }
  };
});
