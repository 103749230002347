define('modules/reporting/campaigns/technology/models/model',['require','Underscore','models/reportingAbstractModel'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const RPTGAbstractModel = require("models/reportingAbstractModel");
  const deviceTypes = Object.freeze(["Desktop", "Smartphone", "Tablet", "Smart-TV", "Unknown"]);
  const inventoryTypes = Object.freeze(["In-App", "Optimized", "Web", "Unknown"]);
  const types = Object.freeze([deviceTypes, inventoryTypes]);
  const deviceColors = Object.freeze(["#4fbbc2", "#3c989e", "#ffa49d", "#f48d6c", "#a9d3f5"]);
  const inventoryColors = Object.freeze(["#a7c9ae", "#ffab48", "#ffe7ad", "#a9d3f5"]);
  const colors = Object.freeze([deviceColors, inventoryColors]);
  const allOthersColor = "#2e6c9e";
  const TechnologyModel = RPTGAbstractModel.extend({
    chartColors: [],
    initialize(options) {
      RPTGAbstractModel.prototype.initialize(options);
    },
    setTechFilters(type, filters, selected) {
      switch (type) {
        case "device":
          this.set({
            techDeviceFilters: filters,
            techFiltersSelected_device: selected
          });
          break;
        case "inventory":
          this.set({
            techInventoryFilters: filters,
            techFiltersSelected_inventory: selected
          });
          break;
      }
    },
    setDeviceData(deviceData, silent) {
      this.set({
        deviceData: deviceData
      }, {
        silent: Boolean(silent)
      });
    },
    setInventoryData(inventoryData, silent) {
      this.set({
        inventoryData: inventoryData
      }, {
        silent: Boolean(silent)
      });
    },
    setDeviceFilterData(deviceFilterData, silent) {
      this.set({
        deviceFilterData: deviceFilterData
      }, {
        silent: Boolean(silent)
      });
    },
    setInventoryFilterData(inventoryFilterData, silent) {
      this.set({
        inventoryFilterData: inventoryFilterData
      }, {
        silent: Boolean(silent)
      });
    },
    getDonutChartData(data, instanceID, selectedItems) {
      const chartData = [];
      const dataKeys = data ? Object.keys(data) : [];
      if (dataKeys.length) {
        const knownKeys = types[instanceID];
        const instanceColors = colors[instanceID];
        const chartColors = [];
        knownKeys.forEach((label, idx) => {
          const value = data[label];
          const selected = label in selectedItems;
          if (value) {
            chartData.push({
              label: label,
              selected: selected,
              value: value
            });
            chartColors.push(instanceColors[idx]);
          }
        });
        const unknownKeys = _.difference(dataKeys, knownKeys);
        if (unknownKeys.length) {
          const twoDigits = 2;
          const combinedValue = unknownKeys.reduce((prevVal, label) => prevVal + Number(data[label]), 0);
          const allOthers = {
            label: "All Others",
            selected: "All Others" in selectedItems,
            value: combinedValue.toFixed(twoDigits)
          };
          chartData.push(allOthers);
          chartColors.push(allOthersColor);
        }
        this.chartColors[instanceID] = chartColors;
      }
      return chartData;
    },
    getDonutChartColors(instanceID) {
      return this.chartColors.length ? [...this.chartColors[instanceID]] : [];
    },
    getTechFilteredData(data, dimKey, metric) {
      let total = 0;
      let zeroCount = 0;
      const perCent = 100;
      const twoDigits = 2;
      const filterData = {};
      data.forEach(item => {
        const val = Number(item[metric]);
        total += val;
        filterData[item[dimKey]] = val;
      });
      Object.entries(filterData).forEach(([key, value]) => {
        if (value > 0) {
          filterData[key] = (value / total * perCent).toFixed(twoDigits);
        } else {
          filterData[key] = "0.00";
          zeroCount++;
        }
      });
      return _.size(data) === zeroCount ? {} : filterData;
    },
    resetTechFilters() {
      this.set({
        deviceData: undefined,
        deviceFilterData: undefined,
        inventoryData: undefined,
        inventoryFilterData: undefined,
        techDeviceFilters: undefined,
        techFiltersSelected_device: undefined,
        techFiltersSelected_inventory: undefined,
        techInventoryFilters: undefined
      }, {
        silent: true
      });
    }
  });
  return TechnologyModel;
});
