function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
define('modules/reporting/campaigns/dataExport/createEdit/views/additionalFilters',['require','Underscore','jQuery','moment','collections/campaignReportV2','T1','T1View','text!../templates/additionalFilters.html'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const moment = require("moment");
  const ReportCollection = require("collections/campaignReportV2");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/additionalFilters.html");
  const T1Publish = T1.EventHub.publish;
  const defaultLabel = "Select Exchanges";
  const debounceTime = 500;
  const reportsWithoutExchange = ["audienceIndexV1", "audienceIndexPixelV1", "audienceVerificationV1", "brainFeatureSummaryV1", "brainFeatureValueV1", "byHourV1", "dataPixelLoadsV1", "eventPixelLoadsV1", "reachFrequencyV1", "watermarkV1"];
  return T1View.extend({
    template: template,
    events: {
      "changed .exchange-search-input": "handleSearch",
      "click .all-checkbox": "handleCheckAll",
      "click .all-search-results": "handleCheckAllSearch",
      "click .exchange-checkbox": "handleCheckboxes"
    },
    dataEvents: {
      DataExportModel: {
        "change:calendarRange": "handleDateRangeChange",
        "change:dateBasedType": "handleDateRangeChange",
        "change:timeWindow": "handleDateRangeChange",
        "change:timeWindowUnit": "handleDateRangeChange",
        "change:reportInfo": function () {
          this.canLoad = this.hasExchange();
          this.reportInfoHasChanged = true;
          this.removeBinItems();
          this.load();
        }
      },
      FilterBinModel: {
        "deselect:additionalFilter": "deselectDDLitem"
      },
      ReportColl: {
        "change:data": "handleLoadedEntities"
      }
    },
    hasExchange() {
      const {
        tag: tag
      } = this.DataExportModel.getReportItem();
      return !reportsWithoutExchange.includes(tag);
    },
    initialize(args) {
      this.DataExportModel = args.dataExportModel;
      this.FilterBinModel = args.binModel;
      this.ReportColl = new ReportCollection();
      this.initLoad = true;
    },
    load() {
      this.dataIsLoading = this.canLoad;
      this.render().then(() => {
        if (this.initLoad) {
          this.initLoad = false;
          T1Publish("load:metaData");
        }
        if (this.canLoad) {
          this.el.css("visibility", "visible");
          this.el.addClass("fade-in");
          this.initializeUIelements();
          this.loadEntities();
        } else {
          this.el.css("visibility", "hidden");
        }
      });
    },
    unload() {
      this.binDeselect = null;
      this.canLoad = null;
      this.dataIsLoading = null;
      this.ddlData = null;
      this.fetchOptions = null;
      this.initLoad = null;
      this.reportInfoHasChanged = null;
      this.ReportColl.abort();
      this.$checkAll = null;
      this.$checkboxes = null;
      this.$ddl = null;
      this.$label = null;
      this.$noSearchResults = null;
      this.$search = null;
      this.$searchAll = null;
    },
    initializeUIelements() {
      this.$checkAll = this.$(".all-checkbox");
      this.$checkboxes = this.$(".exchange-checkbox");
      this.$ddl = this.$(".exchange-selector");
      this.$label = this.$(".filter-btn-label");
      this.$noSearchResults = this.$(".no-search-results");
      this.$search = this.$(".exchange-search-input");
      this.$searchAll = this.$(".all-search-results");
    },
    handleCheckAll() {
      const isChecked = this.$checkAll[0].checked;
      const binChangeItems = {
        add: [],
        remove: []
      };
      const action = isChecked ? "add" : "remove";
      this.$checkboxes.each((idx, item) => {
        const id = String($(item).data("id"));
        const name = $(item).data("name");
        const changeItem = this.createBinChangeItem({
          id: id,
          name: name
        });
        binChangeItems[action].push(changeItem);
      });
      this.updateBinItems(binChangeItems);
      if (isChecked) {
        this.$checkboxes.attr("checked", "checked");
        this.updateLabel("All Exchanges Selected");
      } else {
        this.$checkboxes.removeAttr("checked");
        this.updateLabel(defaultLabel);
      }
      this.$checkAll.removeAttr("state");
      T1Publish("change:formElements", true);
    },
    handleCheckAllSearch() {
      const $searchResults = this.$checkboxes.filter(":visible");
      const isChecked = this.$searchAll[0].checked;
      if (isChecked) {
        const $uncheckedSearchResults = $searchResults.filter(":not(:checked)");
        $uncheckedSearchResults.click();
      } else {
        const $checkedSearchResults = $searchResults.filter((idx, item) => $(item)[0].checked);
        $checkedSearchResults.click();
      }
      this.$searchAll.removeAttr("state");
    },
    handleCheckboxes(evt) {
      setTimeout(() => {
        this.updateState();
        T1Publish("change:formElements", true);
        if (this.$searchAll.is(":visible")) {
          this.updateStateSearch();
        }
        if (this.binDeselect) {
          this.binDeselect = false;
        } else {
          const $clickedItem = $(evt.currentTarget);
          const isChecked = $clickedItem[0].checked;
          const action = isChecked ? "add" : "remove";
          const id = String($clickedItem.data("id"));
          const name = $clickedItem.data("name");
          const binChangeItems = {
            add: [],
            remove: []
          };
          const changeItem = this.createBinChangeItem({
            id: id,
            name: name
          });
          binChangeItems[action].push(changeItem);
          this.updateBinItems(binChangeItems);
        }
      }, 0);
    },
    handleSearch: _.debounce(function (evt) {
      const {
        value: value
      } = evt.originalEvent.detail;
      this.$noSearchResults.hide();
      if (value) {
        const searchTerm = value.toLowerCase();
        this.$checkAll.parent().css("display", "none");
        this.$searchAll.parent().css("display", "block");
        this.$checkboxes.each(function (idx, item) {
          const id = String($(item).data("id")).toLowerCase();
          const name = String($(item).data("name")).toLowerCase();
          if (id.includes(searchTerm) || name.includes(searchTerm)) {
            $(item).parent().css("display", "block");
          } else {
            $(item).parent().css("display", "none");
          }
        });
        this.updateStateSearch();
      } else {
        this.$checkAll.parent().css("display", "block");
        this.$checkboxes.parent().css("display", "block");
        this.$searchAll.parent().css("display", "none");
      }
    }, debounceTime),
    handleDateRangeChange: _.debounce(function () {
      if (!this.reportInfoHasChanged) {
        this.removeBinItems();
        this.load();
      }
    }, debounceTime),
    createBinChangeItem({
      id: id,
      name: name
    }) {
      return {
        isAdditionalFilter: true,
        groupName: "Exchanges",
        id: id,
        name: name,
        path: "",
        pathReversed: true,
        title: name,
        type: "exchange"
      };
    },
    removeBinItems() {
      const binItems = this.DataExportModel.getBinItems();
      const binChangeItems = {
        add: [],
        remove: []
      };
      const additionalFilters = binItems.filter(item => item.isAdditionalFilter);
      if (additionalFilters.length) {
        binChangeItems.remove = additionalFilters;
        this.updateBinItems(binChangeItems);
      }
    },
    updateState() {
      const totalItemNum = this.$checkboxes.length;
      const numOfChecked = this.$checkboxes.filter(":checked").length;
      switch (true) {
        case numOfChecked === 0:
          this.$checkAll.attr("state", "unchecked");
          this.updateLabel(defaultLabel);
          break;
        case numOfChecked === totalItemNum:
          this.$checkAll.attr("state", "checked");
          this.updateLabel("All Exchanges Selected");
          break;
        default:
          this.$checkAll.attr("state", "partial");
          this.updateLabel(`(${numOfChecked}) Exchanges Selected`);
          break;
      }
    },
    updateStateSearch() {
      const $searchResults = this.$checkboxes.filter(":visible");
      const totalItemNum = $searchResults.length;
      const numOfChecked = $searchResults.filter((idx, item) => $(item)[0].checked).length;
      switch (true) {
        case totalItemNum === 0:
          this.$noSearchResults.show();
          this.$searchAll.parent().css("display", "none");
          break;
        case numOfChecked === 0:
          this.$searchAll.attr("state", "unchecked");
          break;
        case numOfChecked === totalItemNum:
          this.$searchAll.attr("state", "checked");
          break;
        default:
          this.$searchAll.attr("state", "partial");
          break;
      }
    },
    updateLabel(txt) {
      this.$label.text(txt);
    },
    updateBinItems(binChangeItems) {
      this.DataExportModel.setBinChangeItems(binChangeItems);
      this.FilterBinModel.updateData();
    },
    deselectDDLitem(id) {
      const $deselectItem = this.$checkboxes.filter(`[data-id="${id}"]`);
      this.binDeselect = true;
      $deselectItem.click();
    },
    getDuration() {
      let duration;
      const exportData = this.DataExportModel.getExportData();
      const {
        startDate: startDate,
        endDate: endDate,
        timeWindow: timeWindow
      } = exportData;
      if (exportData.dateBased) {
        duration = {
          start_date: startDate,
          end_date: endDate ? endDate : moment().format("YYYY-MM-DD")
        };
      } else {
        duration = {
          time_window: timeWindow
        };
      }
      return duration;
    },
    loadEntities() {
      const {
        DataExportModel: DataExportModel
      } = this;
      const org = DataExportModel.getOrgInfo();
      const report = DataExportModel.getReportItem();
      const metric = report.defaultMetrics[0];
      const duration = this.getDuration();
      const reportName = report.fetchName;
      const fetchOptions = this.fetchOptions = _objectSpread(_objectSpread({}, duration), {}, {
        dimensions: "exchange_id,exchange_name",
        filter: `organization_id=${org.id}`,
        metrics: metric,
        order: "exchange_name",
        time_rollup: "all"
      });
      this.ReportColl.fetch({
        fetchOptions: fetchOptions,
        reportName: reportName
      });
    },
    handleLoadedEntities(coll) {
      const {
        DataExportModel: DataExportModel,
        fetchOptions: fetchOptions
      } = this;
      const [dim0, dim1] = fetchOptions.dimensions.split(",");
      const editReport = DataExportModel.get("editReport");
      const collData = coll.toJSON();
      const alreadyHasData = Boolean(this.ddlData && this.ddlData.length);
      this.ddlData = collData.map(item => ({
        id: item[dim0],
        name: item[dim1],
        selected: ""
      }));
      if (editReport && !alreadyHasData) {
        const additionalFilterTypes = DataExportModel.getAdditionalFilterTypes();
        const filterEntities = editReport.definition.filter.split("&");
        const savedAdditionalFilters = filterEntities.reduce((obj, entityStr) => {
          const entityID = entityStr.split("=")[0];
          const entityType = entityID.replace("_id", "");
          if (additionalFilterTypes.includes(entityType)) {
            const ids = entityStr.split("=")[1];
            obj[entityID] = ids.split(",");
          }
          return obj;
        }, {});
        const hasSavedFilters = Boolean(Object.keys(savedAdditionalFilters).length);
        if (hasSavedFilters) {
          const binChangeItems = {
            add: [],
            remove: []
          };
          for (const item of this.ddlData) {
            const isSelected = savedAdditionalFilters.exchange_id.includes(item.id);
            item.selected = isSelected ? "checked" : "";
          }
          for (const id of savedAdditionalFilters.exchange_id) {
            const dataItem = this.ddlData.find(item => id === item.id);
            if (dataItem) {
              const {
                name: name
              } = dataItem;
              const changeItem = this.createBinChangeItem({
                id: id,
                name: name
              });
              binChangeItems.add.push(changeItem);
            }
          }
          this.updateBinItems(binChangeItems);
        }
      }
      this.dataIsLoading = false;
      this.reportInfoHasChanged = false;
      this.render().then(() => {
        this.initializeUIelements();
        this.updateState();
      });
    },
    serialize() {
      const {
        dataIsLoading: dataIsLoading,
        ddlData = [],
        ReportColl: ReportColl
      } = this;
      return {
        canSearch: ddlData.length ? "" : "disabled",
        data: ddlData,
        ddlState: dataIsLoading ? "disabled" : "",
        hasData: Boolean(ReportColl.length),
        spinnerClass: dataIsLoading ? "" : "no-display"
      };
    }
  });
});
