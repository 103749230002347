function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
define('collections/marketplaces',["jQuery", "Underscore", "T1", "T1Collection", "models/marketplace", "T1Sortable"], function ($, _, T1, T1Collection, Marketplace) {
  T1.Models.Marketplaces = T1Collection.extend({
    model: Marketplace,
    apiRoot: COMPASS_ENV.MARKETPLACE_API_BASE,
    searchConfiguration: {
      fields: {
        has_video: {
          type: "enum"
        },
        has_display: {
          type: "enum"
        }
      }
    },
    urlString: "marketplaces",
    fetch: function (options) {
      const success = data => {
        this.add(data.map(marketplace => _objectSpread(_objectSpread({}, marketplace), {}, {
          type: "supply_source",
          id: marketplace.id.toString(),
          rtb_enabled: marketplace.rtb_enabled ? "1" : "0",
          has_display: marketplace.has_display ? "1" : "0",
          has_video: marketplace.has_video ? "1" : "0",
          accountable_and_addressable: "0"
        })));
        if (options.success) {
          options.success(data);
        }
        return this;
      };
      const processAjaxResponse = ({
        data: jsonData,
        meta: meta
      }) => {
        const status = meta && meta.status;
        return {
          jsonData: jsonData,
          statusCode: status === "success" ? "ok" : status
        };
      };
      const syncOpts = {
        action: this.action,
        dataType: "json",
        success: success,
        processAjaxResponse: processAjaxResponse
      };
      return this.sync("read", "", syncOpts);
    },
    parse: function (resp) {
      return T1Collection.prototype.parse.call(this, resp);
    }
  });
  return T1.Models.Marketplaces;
});
